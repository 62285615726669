import React, { FC } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import s from "./MonitorSection.module.scss"
import LangContext from "@contexts/lang"
import { SanityPromotion } from "@utils/types"
import DescriptionPerk from "@components/common/descriptionPerk"
import useResizeWidth from "@hooks/useResizeWidth"

const MonitorSection: FC<{
  sanityPromotion: SanityPromotion
}> = ({ sanityPromotion }) => {
  const {
    title,
    description,
    slug,
    heading,
    descriptionPerks,
  } = sanityPromotion
  const { width } = useResizeWidth()
  const { blueImage } = useStaticQuery(query)
  const { lang } = React.useContext(LangContext)
  const [expanded, setExpanded] = React.useState(0)

  const partialTitle = title[lang].split("|")

  const handleExpanded = (index: number) => {
    setExpanded(expanded === index ? null : index)
  }

  return (
    <section id={slug?.current} className={s.container}>
      <div className={s.monitorSection}>
        <Img
          fluid={blueImage.childImageSharp.fluid}
          className={lang === "en" ? s.blue : `${s.blue} ${s.inverted}`}
        />
        {width > 768 && (
          <div className={s.image}>
            <Img fluid={descriptionPerks[expanded].image?.asset?.fluid} />
          </div>
        )}
        <div className={s.content}>
          {heading && <p className={s.heading}>{heading[lang]}</p>}
          <h2 className={s.title}>{partialTitle}</h2>
          {description && <p>{description[lang]}</p>}
          {descriptionPerks?.length && (
            <div className={`${s.subcontent} ${lang === "ar" && s.inverted}`}>
              {descriptionPerks.map((dp, i) => (
                <DescriptionPerk
                  className={s.descriptionPerk}
                  descriptionPerk={dp}
                  key={i}
                  id={i + 1}
                  expanded={expanded === i}
                  onExpanded={handleExpanded}
                  mainColor="black"
                  secondaryColor="#ffc200"
                  backgroundBlue={true}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default MonitorSection

const query = graphql`
  query monitor {
    blueImage: file(relativePath: { eq: "blue-path.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
