import React, { FC } from "react"
import Img from "gatsby-image"
import s from "./ManageSection.module.scss"
import { SanityPromotion } from "@utils/types"
import LangContext from "@contexts/lang"
import DescriptionPerk from "@components/common/descriptionPerk"
import useResizeWidth from "@hooks/useResizeWidth"

const ManageSection: FC<{
  sanityPromotion: SanityPromotion
}> = ({ sanityPromotion }) => {
  const [expanded, setExpanded] = React.useState(0)
  const {
    title,
    description,
    slug,
    heading,
    descriptionPerks,
  } = sanityPromotion

  const { lang } = React.useContext(LangContext)
  const { width } = useResizeWidth()
  const partialTitle = title[lang].split("<br/>")

  const handleExpanded = (index: number) => {
    setExpanded(expanded === index ? null : index)
  }

  return (
    <section id={slug?.current} className={s.manageSection}>
      {width > 768 && (
        <div className={s.image}>
          <Img fluid={descriptionPerks[expanded].image?.asset?.fluid} />
        </div>
      )}
      <div className={s.content}>
        <p className={s.heading}>{heading[lang]}</p>
        <h2 className={s.title}>{partialTitle}</h2>
        {description && <p>{description[lang]}</p>}
        {descriptionPerks?.length && (
          <div className={`${s.subcontent} ${lang === "ar" && s.inverted}`}>
            {descriptionPerks.map((dp, i) => (
              <DescriptionPerk
                descriptionPerk={dp}
                key={i}
                id={i + 1}
                expanded={expanded === i}
                onExpanded={handleExpanded}
              />
            ))}
          </div>
        )}
      </div>
    </section>
  )
}

export default ManageSection
