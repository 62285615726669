import React, { FC } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import LangContext from "@contexts/lang"
import Img from "gatsby-image"
import s from "./HeroSection.module.scss"
import { SanitySimpleHeroSection } from "@utils/types"
import CTA from "@components/common/cta"

//Components

const HeroSection: FC<{
  sanitySimpleHero: SanitySimpleHeroSection
}> = ({ sanitySimpleHero }) => {
  const { featureImage } = useStaticQuery(query)

  const { lang } = React.useContext(LangContext)
  const { title, subtitle, cta } = sanitySimpleHero
  const partialTitle = title[lang].split("<br/>")

  return (
    <div className={s.heroSection}>
      <Img className={s.feature} fluid={featureImage.childImageSharp.fluid} />
      <div className={s.content}>
        <h2>{partialTitle}</h2>
        <p>{subtitle[lang]}</p>
        {cta?.href && (
          <CTA cta={cta} withArrow color="white" backgroundColor="#1D43FF" />
        )}
      </div>
    </div>
  )
}

export default HeroSection

const query = graphql`
  query hero {
    featureImage: file(relativePath: { eq: "feature.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
