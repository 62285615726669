import React, { FC } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import s from "./SuperviseSection.module.scss"
import { SanityPromotion } from "@utils/types"
import LangContext from "@contexts/lang"
import CTA from "../../common/cta"
import DescriptionPerk from "@components/common/descriptionPerk"
import useResizeWidth from "@hooks/useResizeWidth"

//Components

const SuperviseSection: FC<{
  sanityPromotion: SanityPromotion
}> = ({ sanityPromotion }) => {
  const { yellow } = useStaticQuery(query)
  const { width } = useResizeWidth()
  const { lang } = React.useContext(LangContext)
  const [expanded, setExpanded] = React.useState(0)

  const {
    title,
    description,
    slug,
    heading,
    mainCTA,
    descriptionPerks,
  } = sanityPromotion
  const partialTitle = title[lang].split("<br/>")

  const handleExpanded = (index: number) => {
    setExpanded(expanded === index ? null : index)
  }

  return (
    <section id={slug.current} className={s.superviseSection}>
      <Img
        fluid={yellow.childImageSharp.fluid}
        className={lang === "en" ? s.yellow : `${s.yellow} ${s.inverted}`}
      />
      <div className={lang === "en" ? s.content : `${s.content} ${s.inverted}`}>
        <p className={s.heading}>{heading[lang]}</p>
        <h2 className={s.title}>{partialTitle}</h2>
        {description && <p>{description[lang]}</p>}
        {descriptionPerks?.length && (
          <div className={`${s.subcontent} ${lang === "ar" && s.inverted}`}>
            {descriptionPerks.map((dp, i) => (
              <DescriptionPerk
                descriptionPerk={dp}
                key={i}
                id={i + 1}
                expanded={expanded === i}
                onExpanded={handleExpanded}
                mainColor="black"
              />
            ))}
          </div>
        )}
        {mainCTA?.href && (
          <div className={s.buttonContainer}>
            <CTA
              cta={mainCTA}
              withArrow
              backgroundColor="#742FC4"
              color="white"
            />
          </div>
        )}
      </div>
      {width > 768 && (
        <div className={s.image}>
          <Img fluid={descriptionPerks[expanded].image?.asset?.fluid} />
        </div>
      )}
    </section>
  )
}

export default SuperviseSection

const query = graphql`
  query supervise {
    yellow: file(relativePath: { eq: "yellow-path.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
