import React from "react"
import LangContext from "@contexts/lang"
import { SanityPromotionWithImagesSection } from "@utils/types"
import PromotionWithImage from "@components/common/promotionWithImage"
import s from "./promotionWithImagesSection.module.scss"

const PromotionWithImagesSection: React.FC<{
  promotionWithImages: SanityPromotionWithImagesSection
  className?: string
}> = ({ promotionWithImages, className }) => {
  const { lang } = React.useContext(LangContext)
  const {
    title,
    slug,
    heading,
    description,
    variant,
    images,
  } = promotionWithImages

  return (
    <section
      id={slug.current}
      className={`${s.promotionWithImagesSectionWrapper} ${s[variant]}`}
    >
      <div className={`${className || ""} ${s.promotionWithImagesSection}`}>
        <div className={s.content}>
          {title && <p className={s.heading}>{heading[lang]}</p>}
          {heading && <h2 className={s.title}>{title[lang]}</h2>}
          {description && <p className={s.description}>{description[lang]}</p>}
        </div>
        {images.length && (
          <div className={s.images}>
            {images.map((image, i) => (
              <PromotionWithImage
                promotionWithImage={image}
                variant={variant}
                key={i}
              />
            ))}
          </div>
        )}
      </div>
    </section>
  )
}

export default PromotionWithImagesSection
