import React, { FC } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import s from "./GrowSection.module.scss"
import LangContext from "@contexts/lang"
import { SanityPromotion } from "@utils/types"
import CTA from "../../common/cta"
import DescriptionPerk from "@components/common/descriptionPerk"
import useResizeWidth from "@hooks/useResizeWidth"

const GrowSection: FC<{
  sanityPromotion: SanityPromotion
}> = ({ sanityPromotion }) => {
  const { width } = useResizeWidth()
  const { lang } = React.useContext(LangContext)
  const { aquaImage } = useStaticQuery(query)
  const [expanded, setExpanded] = React.useState(0)

  const {
    title,
    description,
    slug,
    heading,
    mainCTA,
    descriptionPerks,
  } = sanityPromotion
  const partialTitle = title[lang].split("<br/>")

  const handleExpanded = (index: number) => {
    setExpanded(expanded === index ? null : index)
  }

  return (
    <section id={slug?.current} className={s.growSection}>
      <Img fluid={aquaImage.childImageSharp.fluid} className={s.aquaImage} />
      <div className={s.content}>
        <p className={s.heading}>{heading[lang]}</p>
        <h2 className={lang === "en" ? s.title : `${s.title} ${s.inverted}`}>
          {partialTitle}
        </h2>
        {description && <p>{description[lang]}</p>}
        {descriptionPerks?.length && (
          <div className={`${s.subcontent} ${lang === "en" && s.inverted}`}>
            {descriptionPerks.map((dp, i) => (
              <DescriptionPerk
                descriptionPerk={dp}
                key={i}
                id={i + 1}
                expanded={expanded === i}
                onExpanded={handleExpanded}
                mainColor="black"
              />
            ))}
          </div>
        )}
        <div className={s.buttonContainer}>
          {mainCTA && <CTA className={s.button} cta={mainCTA} withArrow />}
        </div>
      </div>
      {width > 768 && (
        <div className={s.image}>
          <Img fluid={descriptionPerks[expanded].image?.asset?.fluid} />
        </div>
      )}
    </section>
  )
}

export default GrowSection

const query = graphql`
  query grow {
    aquaImage: file(relativePath: { eq: "aqua-path-2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
