import React from "react"
import Img from "gatsby-image"
import LangContext from "@contexts/lang"
import { carousel_item_variant, SanityPromotionImage } from "@utils/types"
import CTA from "../cta"
import s from "./promotionWithImage.module.scss"

const PromotionWithImage: React.FC<{
  promotionWithImage: SanityPromotionImage
  variant?: carousel_item_variant
}> = ({ promotionWithImage, variant }) => {
  const { lang } = React.useContext(LangContext)
  const { image, headline, subheadline, cta } = promotionWithImage

  return (
    <div className={`${s.promotionWithImage} ${variant && s[variant]}`}>
      <Img className={s.image} fluid={image?.asset?.fluid} />
      {headline && <p className={s.headline}>{headline[lang]}</p>}
      {subheadline && <p className={s.subheadline}>{subheadline[lang]}</p>}
      {cta && <CTA className={s.cta} cta={cta} withArrow />}
    </div>
  )
}

export default PromotionWithImage
