/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from "react"
import s from "./MobileAppSection.module.scss"
import LangContext from "@contexts/lang"
import Tag from "../../../images/new-tag.svg"

const MobileAppSection: FC<{}> = ({}) => {
  const { lang } = React.useContext(LangContext)
  const sectionContent = [
    "Not just a website, your customers can now download your app from Google Play and App Store. Choose your layout, insert the look and feel of your brand and start receiving orders on the same dashboard as your website.",
    "ليس مجرد موقع اليكتروني ، يمكن لعملائك الآن تنزيل تطبيقك من Google Play و App Store. اختر التصميم الخاص بك ، وأدخل شكل ومظهر علامتك التجارية وابدأ في تلقي الطلبات على نفس لوحة القيادة الخاص بك.",
  ]
  return (
    <section
      className={
        lang === "en"
          ? `${s.mobileAppSection}`
          : `${s.mobileAppSection} ${s.inverted}`
      }
    >
      <div
        className={
          lang === "en" ? `${s.content}` : `${s.content} ${s.inverted}`
        }
      >
        <div className={s.texts}>
          <Tag />
          <h2
            className={
              lang === "en" ? `${s.heading}` : `${s.heading} ${s.inverted}`
            }
          >
            {lang === "en" ? "Own your very own App" : "امتلك تطبيقك الخاص"}
          </h2>
          <p>{sectionContent[lang === "en" ? 0 : 1]}</p>
          <a
            target="_blank"
            href="https://calendly.com/d/cm6-9js-nh5/increase-revenue-with-online-ordering"
            style={{
              textDecoration: "none",
            }}
          >
            <button className={s.btn}>
              {lang === "en" ? "Book a Demo" : "حجز موعد"}
            </button>
          </a>
        </div>
        <div className={s.storeImg} />
      </div>
    </section>
  )
}
export default MobileAppSection
