import React, { FC } from "react"
import { graphql } from "gatsby"
import { SanityPage, sanity_typename } from "@utils/types"
import countryContentFilter from "@utils/countryContentFilter"
import LangContext from "@contexts/lang"
//Components
import HeroSection from "@components/featurePage/HeroSection"
/* import CustomizeSection from "@components/featurePage/CustomizeSection" */
import ManageSection from "@components/featurePage/ManageSection"
/* import StreamlineSection from "@components/featurePage/StreamlineSection" */
import SuperviseSection from "@components/featurePage/SuperviseSection"
/* import ActivateSection from "@components/featurePage/ActivateSection" */
import MonitorSection from "@components/featurePage/MonitorSection"
/* import AnalyzeSection from "@components/featurePage/AnalyzeSection" */
import GrowSection from "@components/featurePage/GrowSection"
import SEO from "@components/common/seo/seo"
import PromotionWithImagesSection from "@components/featurePage/promotionWithImagesSection"
import MobileAppSection from "@components/featurePage/MobileAppSection"

type Props = {
  data: { features: SanityPage }
  location: string
}

const FeaturePage: FC<Props> = ({ data: { features }, location }) => {
  const { lang, countryCode } = React.useContext(LangContext)

  const sanityHero = countryContentFilter(
    features,
    countryCode,
    sanity_typename.SanitySimpleHeroSection
  )[0]

  const sanityPromotion = countryContentFilter(
    features,
    countryCode,
    sanity_typename.SanityPromotionSection
  )

  const promotionWithImages = countryContentFilter(
    features,
    countryCode,
    sanity_typename.SanityPromotionWithImagesSection
  )

  return (
    <>
      <SEO title={features.title[lang]} location={location} />
      <HeroSection sanitySimpleHero={sanityHero} />
      <PromotionWithImagesSection
        promotionWithImages={promotionWithImages[0]}
      />
      <MobileAppSection promotionWithImages={promotionWithImages[0]} />
      <ManageSection sanityPromotion={sanityPromotion[0]} />
      <PromotionWithImagesSection
        promotionWithImages={promotionWithImages[1]}
      />
      <SuperviseSection sanityPromotion={sanityPromotion[1]} />
      <PromotionWithImagesSection
        promotionWithImages={promotionWithImages[2]}
      />
      <MonitorSection sanityPromotion={sanityPromotion[2]} />
      <PromotionWithImagesSection
        promotionWithImages={promotionWithImages[3]}
      />
      <GrowSection sanityPromotion={sanityPromotion[3]} />
    </>
  )
}

export default FeaturePage

export const query = graphql`
  {
    features: sanityPage(slug: { current: { eq: "/features" } }) {
      title {
        en
        ar
      }
      content {
        ...SanitySimpleHero
        ...SanityCarouselSection
        ...SanityPromotion
        ...SanityPromotionWithImages
      }
    }
  }
`
